import logo from './portaitPupper.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="Giroud the presidental candidate" alt="logo" />
        <h1>Pupper for Prez designs coming soon!</h1>
      </header>
    </div>
  );
}

export default App;
